.landing-page { overflow-x: hidden; background-color: black }
.banner-know {
  position: absolute;
  bottom: 30px;
  left: 50%;
  transform: translateX(-50%); }
.page-background {
  background-image: url('../assets/img/backgrounds/aussie-forest-3.jpg');
  background-position: center;
  background-size: cover;
  position: relative;
  animation: fade-in 7s alternate;  
}
.banner-overlay {  
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 1;
  background: rgb(0,0,0);
  background: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 100%); 
  z-index: 0; }
.page-banner {
  padding-top: 10%;
  background: rgb(0,0,0);
  background: linear-gradient(180deg, rgba(0,0,0,0.32) 0%, rgba(255,255,255,0) 100%);
  height: 100vh; }
.story { 
  position: relative;
  background: rgb(0,0,0);
  background: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(255,255,255,0) 100%); 
  padding-bottom: 170px; }
.faq {
  background: rgb(0,0,0);
  background: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(255,255,255,0) 100%); 
  padding-top: 100px;
  padding-bottom: 200px; }
.inverted-overlay {
  position: absolute;
  top:0;
  left:0;
  width: 100%;
  height: 100%;
  background: rgb(0,0,0);
  background: linear-gradient(180deg, rgba(0,0,0, 0.9) 0%, rgba(255,255,255,0) 100%); }

.map { width: 100%; }

.avatar {
  height: 250px;
  width: 250px;
  background-color: #DDD;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  animation: do-levitation 1.2s alternate ease-in-out infinite; }

@keyframes do-levitation {
  0% { transform: translate(0, 0); }
  100% { transform: translate(0, 0.7em); }
}

.avatar-image {
  height: 170px;
  object-fit: cover;
  object-position: center; }

.separator { 
  width: 80%;
  border: 1px #DDD solid;
  align-self: center;
  margin-top: 15px;
  margin-bottom: 15px; }
.copyright { font-weight: 500; }


.slide-top {
	-webkit-animation: slide-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  animation: slide-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both; }

@-webkit-keyframes slide-top {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  100% {
    -webkit-transform: translateY(-100px);
            transform: translateY(-100px);
  }
}
@keyframes slide-top {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  100% {
    -webkit-transform: translateY(-100px);
            transform: translateY(-100px);
  }
}
.fade-in{-webkit-animation:fade-in 5s cubic-bezier(.39,.575,.565,1.000) both;animation:fade-in 2.2s cubic-bezier(.39,.575,.565,1.000) both}
@-webkit-keyframes fade-in{0%{opacity:0}100%{opacity:1}}@keyframes fade-in{0%{opacity:0}100%{opacity:1}}
// ----------------------------
.banner-title { 
  font-weight: 500;
  font-size: calc(45px + 0.6vw);
  color: white !important; }
.banner-subtitle { 
  font-weight: 500;
  font-size: calc(18px + 0.4vw);
  color: white !important; }
.banner-description {
  font-weight: 500;
  font-size: calc(15px + 0.4vw);
  color: #DDD !important; }
// ----------------------------


// TESTIMONIAL -----------------
.jane-godall {
  position: relative;
  width: 100%;
  height: 70vh;
  background-image: url('../assets/img/partners/Jane-Goodall-12.jpg'); 
  background-size: cover;
  background-position: center; }
.the-crossing {
  position: relative;
  width: 100%;
  height: 70vh;
  background-image: url('../assets/img/partners/thecrossing.jpg'); 
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center; }
.the-crossing-container {
  background-color: white;
  padding-top: 50px;
  position: relative;
  padding-bottom: 260px; }
.image-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0.9;
  background: linear-gradient(180deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0.2) 100%); 
  z-index: 0; }
.testimonial-name {
  color: #FFF;
  font-size: calc(30px + 0.4vw); 
  font-weight: 500;
  text-align: center; }
.testimonial-text {
  color: #DDD;
  font-size: calc(12px + 0.4vw);
  font-weight: 500;
  text-align: center; }
.testimonial-caption {
  position: absolute;
  bottom: 5%;
  width: 75%;
  left: 50%;
  transform: translateX(-50%); }
//-----------------------------------------

.team-container {
  background: linear-gradient(180deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0.2) 100%); }

.footer-title {
  font-size: calc(25px + 0.4vw);
  text-align: center;
  color: white; }

// ----------------------------

.fog-container { position: relative; z-index: 1; }
.moving-clouds { opacity: 0.65; }

.card-register {
  background-color: rgba(131, 134, 132, 0.62);
  border-radius: 8px;
  color: #fff;
  max-width: unset;
  margin: 20px 0 70px;
  min-height: 400px;
  padding: 30px; }
  .card-register label {
    margin-top: 0px !important; }
  .card-register .card-title {
    color: #B33C12;
    text-align: center; }
  .card-register .btn {
    margin-top: 0px !important; }
  .card-register .forgot {
    text-align: center; }
  .card-register .input-group-text {
    border: 1px solid #FFFFFF; }
  
.page-carousel {
  border-radius: 12px !important;
  border: none !important;
  align-self: center;
  width: 100%;
 }
  
 @media screen and (max-width: 600px) {
   .carousel-item {
     width: 100%;
     height: 200px; }
   .the-crossing-container { padding-bottom: 300px; }
 }

 @media screen and (min-width: 601px) {
  .carousel-item {
    width: 100%;
    height: 500px;
  }
}
// ----------------- COMMON
.title { font-weight: 500 }
.white { color: white }
.black { color: black }
.bold { font-weight: 600 !important }
.page-header { max-height: unset; background-color: black; }
.quotation { font-size: 30px; }

.story-text {
  color: #DDD;
  font-size: 18px;
  font-weight: 400; }

.button-checkout { margin-left: 5px; }
.form-control { opacity: 0.85 }
.checkout-item { width: 100%; margin-bottom: 15px; }
.checkout-card { opacity: 0.9; padding: 20px; }
.card-input { width: 100%; }
.credit-card-input { font-weight: 600 !important }

.btn:disabled {
  pointer-events: none;
  cursor: help;
}

@media screen and (max-width: 500px) {
  .card-image {
    max-width: 23px !important;
    border-radius: 0px !important; }
  .font-adjusted { font-size: 20px; }
  .wrapped-col { margin-top: 20px; }
  .content-center { margin-top: 25px !important; }
}

@media screen and (min-width: 501px) {
  .card-image {
    max-width: 30px !important;
    border-radius: 0px !important;
  }
}

.kJnpoy { border: 1px solid #DDD; padding: 7px 12px; }
.white { color: #DDD !important; font-weight:400; }
.just-white { color: #DDD !important; }
.card-profile { height: 100%; }
.card-avatar { height: 120px; width: 120px;}
.smaller { font-size: 40px; }
.subtitle-smaller { font-size: 17px; color: #EEE; }
.moving-clouds {
  pointer-events: none;
  animation-duration: 30s !important;
  z-index: 0 !important;
  margin-bottom: 20px; }
.font-adjusted { font-size: calc(5px + 0.2vw); }
.navbar-transparent { padding-top: 0px !important; }
.button-enphasis {
  font-size: 15px;
  width: 65%;
  min-width: 130px;
  font-weight: 800 !important; }
.nav-item { cursor: pointer; }
.scrolltop-button { opacity: 0.7; }
.card-description { font-weight: 400 !important; }

.sub-select { font-size: 12px; font-weight: 600 !important; }
.donate-input::placeholder { font-weight: 600 !important; }
.donate-input {  position:relative; font-weight: 800; font-size: 12px; }
.donate-input::after {
  content: 'USD';
  font-weight: 800;
  position: absolute;
  top: 50%;
  right: 25px;
  transform: translateY(-50%); }
.card img {
  height: 100%;
  width: 100%;
}
.hlTfmo { margin-bottom: 0px !important; }

.footer-item {
  color: #777;
  display: block;
  opacity: .8;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 600;
}